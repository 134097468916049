import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import NavBar from '../../components/NavBar';
import FlowForm from '../../components/FlowForm';
import CookieBanner from '../../components/CookieBanner';
import Header from '../../components/Header';
import FlowComponent from '../../components/FlowComponent';
import Portfolio from '../../components/Portfolio';
import Reviews from '../../components/Reviews';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';

export default function MainPage({ location }) {

  const { i18n } = useTranslation();

  const [lang, setLang] = useState();
  const [showFloatingForm, setShowFloatingForm] = useState(false);

  useEffect(() => {
    if (isEnglish()) {
      i18n.changeLanguage('en');
      setLang('en');
    } else {
      i18n.changeLanguage('de');
      setLang('de');
    }
  }, [i18n])

  useEffect(() => {
    if (localStorage.getItem('allowCookie') === 'true') {
      setShowFloatingForm(true)
    }
  }, [])

  useEffect(() => {
    if (location.state) {
      window.scrollTo(0, location.state.scroll)
    }
  }, [])

  const isEnglish = () => {
    let path = window.location.pathname;
    console.log(path);
    return (/(\/en)/g).test(path);
  }

  return (
    <div>
      <NavBar location={location}/>
      {showFloatingForm && <FlowForm showFormPoint={300} />}
      <CookieBanner enableForm={setShowFloatingForm} />
      <Header />
      <FlowComponent lang={lang} />
      <Portfolio />
      <Reviews />
      <ContactUs lang={lang} />
      <Footer />
    </div>
  )
}
